import * as types from "../types/index";

const intialState = {
  isLoading: false,
  allPlans: null,
  data: null,
  subscription: null,
  error: null,
};

export const stripeReducer = (state = intialState, action) => {
  switch (action.type) {
    case types.GET_STRIPE_PLANS:
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    case types.GET_STRIPE_PLANS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allPlans: action.payload.data,
        error: null,
      };
    }
    case types.GET_STRIPE_PLANS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.payload.error,
      };
    }
    case types.GET_STRIPE_SUBSCRIPTION:
      return {
        isLoading: true,
        subscription: null,
        error: null,
      };
    case types.GET_STRIPE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        subscription: action.payload,
        error: null,
      };
    }
    case types.GET_STRIPE_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
