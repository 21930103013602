import createReducer from "@utils/createReducer";

export const SET_ITEM = "profile/SET_ITEM";
export const CLEAR_ITEMS = "profile/CLEAR_ITEMS";

const initialState = {};

export const localStorageReducer = createReducer(initialState, {
  [SET_ITEM](state, action) {
    const { key, value } = action.payload;
    if (value) {
      return Object.assign({}, state, {
        ...state,
        [key]: value,
      });
    } else {
      return Object.assign({}, state, {
        ...state,
        [key]: null,
      });
    }
  },
  [CLEAR_ITEMS](state) {
    return Object.assign({}, state, {});
  },
});
