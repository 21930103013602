import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { makeStyles } from "@mui/styles";
import { Paper, Typography, Link, Button } from "@mui/material";
import getLanguage from "@utils/getLanguage";
import {
  PRIVACY_POLICY_LINK,
  PRIVACY_POLICY_LINK_NORWEGIAN,
} from "@utils/appConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 420,
    position: "fixed",
    bottom: 0,
    left: 0,
    margin: theme.spacing(3),
    outline: "none",
    zIndex: 2000,
  },
  content: {
    padding: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(0, 2, 2, 2),
  },
}));

function CookiesNotification() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const language = getLanguage();

  // prevent this popup on the Signup page as it has the Privacy policy consent in the form
  const isSignupPage = window.location.pathname === "/signUp";

  const handleClose = () => {
    Cookies.set("consent", "true", { expires: 365 });
    setOpen(false);
  };

  useEffect(() => {
    const consent = Cookies.get("consent");

    if (!consent && !isSignupPage) {
      setOpen(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Paper className={classes.root} elevation={3}>
      <div className={classes.content}>
        <Typography variant="body1">
          {language === "nb"
            ? `Vi bruker informasjonskapsler for å sikre at vi gir deg den beste opplevelsen på nettstedet vårt. Les vår `
            : `We use Cookies to ensure that we give you the best experience on our website. Read our `}
          <Link
            className={classes.link}
            component="a"
            href={
              language === "nb"
                ? PRIVACY_POLICY_LINK_NORWEGIAN
                : PRIVACY_POLICY_LINK
            }
            target="_blank"
            underline="hover"
          >
            {language === "nb" ? `Personvern` : `Privacy Policy`}
          </Link>
          .
        </Typography>
      </div>
      <div className={classes.actions}>
        <Button
          className={classes.agreeButton}
          color="primary"
          onClick={handleClose}
          variant="contained"
        >
          {language === "nb" ? `Jeg er enig` : `I Agree`}
        </Button>
      </div>
    </Paper>
  );
}

export default CookiesNotification;
