import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
import { rootReducer } from "./rootReducer";
import * as Sentry from "@sentry/react";
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const initialState = {};
const enhancers = [sentryReduxEnhancer];
const middleware = [thunk];

if (import.meta.env.DEV) {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(
      devToolsExtension({
        name: "vrs-frontend-store",
        instanceId: "vrs-frontend-store",
      })
    );
  }
}

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["commonReducer", "companyReducer"], // only commonReducer will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(persistedReducer, initialState, composedEnhancers);

const persistor = persistStore(store);

export { store, persistor };
