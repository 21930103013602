import React from "react";
import spinner from "@images/spinner.svg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  overlayDiv: {
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.overlayIndicator,
    opacity: 0.8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
    zIndex: 9999,
  },
  overlaySpinner: {
    width: "150px",
  },
}));

const OverlayLoadingIndicator = ({ isLoading }) => {
  const classes = useStyles();

  return (
    isLoading && (
      <div className={classes.overlayDiv} style={{ zIndex: 1001 }}>
        <img
          src={spinner}
          className={classes.overlaySpinner}
          alt="Loading..."
        />
      </div>
    )
  );
};

export default OverlayLoadingIndicator;
