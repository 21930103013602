import { post } from "../utils/api.config.js";

export const logoutApi = (params) => {
  const request = {
    subUrl: `/oauth/revoke`,
    headers: {
      "Content-Type": "application/json",
      Accept: "*",
      Authorization: "Bearer " + import.meta.env.VITE_AUTHORIZED_CLIENT_TOKEN,
    },
    data: params,
  };
  return post(request);
};
