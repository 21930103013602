import store from "@/redux/store/localStorageStore";
import { setParam, clearParams } from "../redux/actions/localStorage";
import { Buffer } from "buffer";

export function setItem(key, value) {
  store.dispatch(setParam(key, value));

  const keyEncode = Buffer.from(key).toString("base64");
  const valueEncode = Buffer.from(value.toString()).toString("base64");

  localStorage.setItem(keyEncode, valueEncode); // encode key/value pair
}

export function getItem(key) {
  const state = store.getState();

  let value = null;
  if (Object.prototype.hasOwnProperty.call(state, key)) {
    value = state[key];
  } else {
    const keyEncode = Buffer.from(key).toString("base64");
    if (localStorage.getItem(keyEncode)) {
      value = Buffer.from(localStorage.getItem(keyEncode), "base64").toString();

      setItem(key, value); // add the missing value in store
    }
  }

  return value;
}

export function removeItem(key) {
  const state = store.getState();

  if (Object.prototype.hasOwnProperty.call(state, key)) {
    delete state[key];
  }

  const keyEncode = Buffer.from(key).toString("base64");
  localStorage.removeItem(keyEncode);
}

export function clearAllItems() {
  store.dispatch(clearParams());
  localStorage.clear();
}
