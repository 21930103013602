import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "../../utils/api.config";

const initialState = {
  isLoading: false,
  blocks: null,
  lineGraph: null,
  hourAddonTypeSummary: null,
  hoursByUser: null,
  error: null,
};

export const dashboardReducer = createReducer(initialState, {
  [types.GET_DASHBOARD_BLOCKS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      blocks: null,
      error: null,
    });
  },
  [types.GET_DASHBOARD_BLOCKS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      blocks: action.payload,
      isLoading: false,
    });
  },
  [types.GET_DASHBOARD_BLOCKS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },

  [types.GET_HOURS_BY_USER](state) {
    return Object.assign({}, state, {
      ...state,
      //isLoading: true,
      hoursByUser: null,
      error: null,
    });
  },
  [types.GET_HOURS_BY_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      hoursByUser: action.payload,
      //isLoading: false,
    });
  },
  [types.GET_HOURS_BY_USER_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      //isLoading: false,
    });
  },

  [types.GET_DASHBOARD_LINEGRAPH](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      lineGraph: null,
      error: null,
    });
  },
  [types.GET_DASHBOARD_LINEGRAPH_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      lineGraph: action.payload,
      isLoading: false,
    });
  },
  [types.GET_DASHBOARD_LINEGRAPH_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },

  [types.GET_HOUR_ADDON_TYPE_SUMMARY](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      hourAddonTypeSummary: null,
      error: null,
    });
  },
  [types.GET_HOUR_ADDON_TYPE_SUMMARY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      hourAddonTypeSummary: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HOUR_ADDON_TYPE_SUMMARY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
});
