//
import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "../../utils/api.config";

const initialState = {
  isLoading: false,
  leaveRequest: {},
  leaveHoursPerDay: {},
  error: null,
  approvalSuccess: null,
  approvalError: null,
  leaveHoursByReason: [],
};

export const leaveReducer = createReducer(initialState, {
  [types.GET_LEAVE_REQUESTS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_LEAVE_REQUESTS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      leaveRequest: action.payload,
      isLoading: false,
    });
  },
  [types.GET_LEAVE_REQUESTS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      itemList: {},
      error: action.payload,
      isLoading: false,
    });
  },

  [types.GET_LEAVE_HOURS_BY_REASON](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_LEAVE_HOURS_BY_REASON_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      leaveHoursByReason: action.payload,
      isLoading: false,
    });
  },
  [types.GET_LEAVE_HOURS_BY_REASON_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      leaveHoursByReason: [],
      error: action.payload,
      isLoading: false,
    });
  },

  [types.GET_LEAVE_HOURS_PER_DAY](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_LEAVE_HOURS_PER_DAY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      leaveHoursPerDay: action.payload,
      isLoading: false,
    });
  },
  [types.GET_LEAVE_HOURS_PER_DAY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      leaveHoursPerDay: {},
      error: action.payload,
      isLoading: false,
    });
  },

  [types.APPROVE_HOURS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.APPROVE_HOURS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.APPROVE_HOURS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
});
