import React, { useState, useEffect, Suspense } from "react";
import { Route, Switch, Router } from "react-router-dom";
import { APP_ROUTES } from "../utils/appConstants";
import { store, persistor } from "@/redux/store/store";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { actions as authActions } from "../app/auth";
import { SnackbarProvider } from "notistack";
import { lazyRetry } from "../utils/utils";
import CookiesNotification from "../components/common/CookiesNotification";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import "../App.css";
import OverlayLoadingIndicator from "@common/OverlayLoadingIndicator";
import ThemeProvider from "../theme";
import { CollapseDrawerProvider } from "@contexts/CollapseDrawerContext";
import { LanguageProvider } from "@contexts/LanguageContext";

import ChartStyle from "../components/common/Chart/ChartStyle";
import { IntercomProvider } from "react-use-intercom";

import { LicenseInfo } from "@mui/x-license-pro";
import ErrorPage from "@/components/common/ErrorPage";

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUIX_LICENSE_KEY);

const fallback = <ErrorPage />;

const Main = React.lazy(() =>
  lazyRetry(() => import("../components/layouts/Main"), "app")
);
const LoginContainer = React.lazy(() =>
  lazyRetry(() => import("../containers/session/LoginContainer"), "app")
);
const ForgotPassword = React.lazy(() =>
  lazyRetry(() => import("../containers/forgotPassword"), "app")
);
const ResetPassword = React.lazy(() =>
  lazyRetry(() => import("../containers/resetPassword"), "app")
);
const SignUpContainer = React.lazy(() =>
  lazyRetry(() => import("../containers/signUp/SignUpContainer"), "app")
);
const ThankYouScreen = React.lazy(() =>
  lazyRetry(() => import("../containers/session/ThankYouScreen"), "page")
);

const browserHistory = createBrowserHistory();

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    handleToggleAuth();
  }, []);

  const handleToggleAuth = () => {
    setIsAuthenticated(authActions.isLoggedIn() ? true : false);
  };

  return (
    <IntercomProvider appId={import.meta.env.VITE_INTERCOM_APP_ID}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CollapseDrawerProvider>
            <ThemeProvider>
              <Sentry.ErrorBoundary fallback={fallback}>
                <SnackbarProvider
                  maxSnack={1}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <Router history={browserHistory}>
                    <Suspense
                      fallback={<OverlayLoadingIndicator isLoading={true} />}
                    >
                      <CookiesNotification />
                      <LanguageProvider>
                        <Switch>
                          <Route
                            exact
                            path={APP_ROUTES.login}
                            render={(props) => (
                              <LoginContainer
                                {...props}
                                onToggleAuth={() => handleToggleAuth()}
                              />
                            )}
                          />
                          <Route
                            exact
                            path={APP_ROUTES.signUp}
                            render={(props) => (
                              <SignUpContainer
                                {...props}
                                onToggleAuth={() => handleToggleAuth()}
                              />
                            )}
                          />
                          <Route
                            exact
                            path={APP_ROUTES.forgotPassword}
                            render={(props) => <ForgotPassword {...props} />}
                          />
                          <Route
                            exact
                            path={APP_ROUTES.resetPassword}
                            render={(props) => <ResetPassword {...props} />}
                          />
                          <Route
                            exact
                            path={APP_ROUTES.postSignup}
                            render={(props) => (
                              <ThankYouScreen
                                {...props}
                                onToggleAuth={() => handleToggleAuth()}
                              />
                            )}
                          />
                          <Route
                            exact
                            path={APP_ROUTES.confirmed}
                            render={(props) => (
                              <LoginContainer
                                {...props}
                                onToggleAuth={() => handleToggleAuth()}
                              />
                            )}
                          />
                          <Route
                            render={(props) =>
                              isAuthenticated ? (
                                <Main
                                  {...props}
                                  onToggleAuth={() => handleToggleAuth()}
                                />
                              ) : (
                                <LoginContainer
                                  {...props}
                                  onToggleAuth={() => handleToggleAuth()}
                                />
                              )
                            }
                          />
                        </Switch>
                      </LanguageProvider>
                    </Suspense>
                  </Router>
                </SnackbarProvider>
                <ChartStyle />
              </Sentry.ErrorBoundary>
            </ThemeProvider>
          </CollapseDrawerProvider>
        </PersistGate>
      </Provider>
    </IntercomProvider>
  );
}

export default Sentry.withProfiler(App);
