import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "@utils/api.config";

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  categoriesList: {},
  categoryData: {},
};

export const categoryReducer = createReducer(initialState, {
  [types.ADD_CATEGORY](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.ADD_CATEGORY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      successMessage: action.payload,
      errorMessage: null,
    });
  },
  [types.ADD_CATEGORY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      successMessage: null,
      errorMessage: action.payload,
    });
  },
  [types.GET_CATEGORIES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
    });
  },
  [types.GET_CATEGORIES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      categoriesList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_CATEGORIES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.GET_CATEGORY](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.GET_CATEGORY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      categoryData: action.payload,
      isLoading: false,
    });
  },
  [types.GET_CATEGORY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.UPDATE_CATEGORY](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.UPDATE_CATEGORY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      successMessage: action.payload,
      errorMessage: null,
      isLoading: false,
    });
  },
  [types.UPDATE_CATEGORY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      successMessage: null,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_CATEGORY](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.DELETE_CATEGORY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      successMessage: action.payload,
      errorMessage: null,
      isLoading: false,
    });
  },
  [types.DELETE_CATEGORY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      successMessage: null,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
});
