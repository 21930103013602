import * as types from "../types/index";

const intialState = {
  isLoading: false,
  data: {},
  error: null,
  isUserLoggedIn: false,
};

export const signIn = (state = intialState, action) => {
  switch (action.type) {
    case types.POST_LOGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.POST_LOGIN_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        data,
        isLoading: false,
        isUserLoggedIn: true,
      };
    }
    case types.POST_LOGIN_FAILURE: {
      const { error } = action.payload;
      return {
        ...intialState,
        error,
        isLoading: false,
      };
    }
    case types.POST_LOGOUT_SUCCESS: {
      return {
        ...intialState,
        isUserLoggedIn: false,
      };
    }
    default:
      return state;
  }
};
