import PropTypes from "prop-types";
import { createContext, useState, useEffect } from "react";
// hooks
import useResponsive from "../hooks/useResponsive";

// ----------------------------------------------------------------------

const initialState = {
  collapseClick: false,
  collapseHover: false,
  themeMode: localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light",
  onToggleCollapse: () => {},
  onHoverEnter: () => {},
  onHoverLeave: () => {},
  onToggleMode: () => {},
};

const CollapseDrawerContext = createContext(initialState);

// ----------------------------------------------------------------------

CollapseDrawerProvider.propTypes = {
  children: PropTypes.node,
};

function CollapseDrawerProvider({ children }) {
  const isDesktop = useResponsive("up", "lg");

  const [collapse, setCollapse] = useState({
    click: false,
    hover: false,
  });

  const [settings, setSettings] = useState({
    themeMode: initialState.themeMode,
  });

  useEffect(() => {
    if (!isDesktop) {
      setCollapse({
        click: false,
        hover: false,
      });
    }
  }, [isDesktop]);

  const handleToggleCollapse = () => {
    setCollapse({ ...collapse, click: !collapse.click });
  };

  const handleHoverEnter = () => {
    if (collapse.click) {
      setCollapse({ ...collapse, hover: true });
    }
  };

  const handleHoverLeave = () => {
    setCollapse({ ...collapse, hover: false });
  };

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === "light" ? "dark" : "light",
    });

    localStorage.setItem(
      "theme",
      settings.themeMode === "light" ? "dark" : "light"
    );
  };

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: collapse.click && !collapse.hover,
        collapseClick: collapse.click,
        collapseHover: collapse.hover,
        themeMode: settings.themeMode,
        onToggleCollapse: handleToggleCollapse,
        onHoverEnter: handleHoverEnter,
        onHoverLeave: handleHoverLeave,
        onToggleMode,
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
}

export { CollapseDrawerProvider, CollapseDrawerContext };
