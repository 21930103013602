//@flow
import { logoutApi } from "../services/logout";
import axios from "axios";
import { actions as authActions } from "../app/auth";
import { clearAllItems, getItem, setItem } from "./localStorage";
import * as Sentry from "@sentry/browser";

export const authTokenKey = "AUTH_TOKEN";
export const responseHeaderKeys = {
  pagination: "x-pagination",
};

const baseUrl = import.meta.env.VITE_API_V1_URL;
const apiDomain = import.meta.env.VITE_API_BASE_URL;

export const get = (request) => {
  return commonFetch({ method: "get", ...request });
};

export const post = (request) => {
  return commonFetch({ method: "post", ...request });
};

export const patch = (request) => {
  return commonFetch({ method: "patch", ...request });
};

export const put = (request) => {
  return commonFetch({ method: "put", ...request });
};

export const deletee = (request) => {
  return commonFetch({ method: "delete", ...request });
};

const commonFetch = (request) => {
  const { subUrl, method, data = {}, params = {}, headers = {} } = request;
  const url = request.url || getFullUrl(subUrl);
  return axios({
    method,
    url,
    data,
    headers: { ...headers },
    params,
  }).then((response) => {
    if (handleResponseStatus(response)) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  });
  // don't add .catch(), othewise the reducers will not catch error like 404
};

const handleResponseStatus = (response) => {
  if (response.status >= 200 || response.status < 300) {
    return true;
  }
  return false;
};

const getFullUrl = (url) => {
  return `${baseUrl}${url}`;
};

export const getBaseUrl = () => {
  return `${baseUrl}`;
};

export const getApiDomain = () => {
  return apiDomain;
};

export const getErrorObject = (error) => {
  let message = "Error!";
  if (error && error.response && error.response.data) {
    // API server error message
    let errorResponse = error.response.data;
    if (errorResponse.message) {
      message = errorResponse.message;
    } else if (errorResponse.error_description) {
      message = errorResponse.error_description;
    } else if (errorResponse.error) {
      message = errorResponse.error;
    }
  } else if (error && error.message) {
    // js error message
    message = error.message;
  }
  return {
    message,
  };
};

export const getErrorCode = (error) => {
  let errorCode = null;
  if (error && error.response) {
    errorCode = error.response.status;
  }
  return errorCode;
};

export const logout = async () => {
  const postObj = {
    client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
    client_secret: import.meta.env.VITE_OAUTH_CLIENT_SECRET,
    token: authActions.getAccessToken(),
  };
  try {
    await logoutApi(postObj);
    authActions.clearSession();
    cleanAndReload();
  } catch (e) {
    // catch 401 error to prevent sentry error, clear session and forward to login page
    authActions.clearSession();
    cleanAndReload();
  }
};

export function cleanAndReload() {
  cleanData();
  Sentry.setUser(null);
  setTimeout(() => {
    reload();
  }, 1000);
}

export function cleanData() {
  const isDemoData = getItem("demoData") || false; // prevent removing the demoData key

  clearAllItems(); // remove all localStorage items and respective redux params

  localStorage.clear();

  if (isDemoData) {
    setItem("demoData", true);
  }

  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    // do not remove cookie consent
    if (name.trim() === "consent") continue;

    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

function reload() {
  //document.location.reload();
  //window.location.reload();
  window.location.replace("/login");
}
