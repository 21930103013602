//
import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "../../utils/api.config";

const initialState = {
  isLoading: false,
  onboardingStatus: false,
  userOnboardingSteps: null,
  exportFilePath: null,
  exportFileName: null,
  showDemoData: false,
  error: null,
  userOnboardingStepsError: null,
  onboardingStepUpdateMessage: null,
  importFileSuccess: null,
  importFileError: null,
  downloadStatus: null,
};

export const onboardingReducer = createReducer(initialState, {
  [types.GET_ONBOARDING_STEPS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      // onboardingStatus: false,
      userOnboardingSteps: null,
      exportFilePath: null,
      showDemoData: null,
      error: null,
    });
  },
  [types.GET_ONBOARDING_STEPS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      onboardingStatus: action.payload.onboarding_status,
      userOnboardingSteps: action.payload.onboarding_steps,
      showDemoData: action.payload.show_demo_data,
      exportFilePath: action.payload?.xlsx_file_url
        ? action.payload.xlsx_file_url
        : null,
      exportFileName: action.payload?.xlsx_file_name
        ? action.payload.xlsx_file_name
        : null,
      downloadStatus: action.payload?.download_status
        ? action.payload.download_status
        : null,
      validationStatus: action.payload?.validation_status
        ? action.payload.validation_status
        : null,
      validRecords: action.payload?.valid_records
        ? action.payload.valid_records
        : null,
      invalidRecords: action.payload?.invalid_records
        ? action.payload.invalid_records
        : null,
      importStatus: action.payload?.import_status
        ? action.payload.import_status
        : null,
      successfullyImported: action.payload?.imported_successfully
        ? action.payload.imported_successfully
        : null,
      error: null,
    });
  },
  [types.GET_ONBOARDING_STEPS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      userOnboardingStepsError: action.payload,
    });
  },

  [types.PUT_ONBOARDING_STEPS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      onboardingStepUpdateMessage: null,
      error: null,
    });
  },
  [types.PUT_ONBOARDING_STEPS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      onboardingStepUpdateMessage: action.payload,
      userOnboardingSteps: action.payload?.onboarding_steps,
      isLoading: false,
    });
  },
  [types.PUT_ONBOARDING_STEPS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      onboardingStepUpdateMessage: action.payload,
      isLoading: false,
    });
  },

  [types.IMPORT_DATA](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      importFileSuccess: null,
      importFileError: null,
    });
  },
  [types.IMPORT_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      importFileSuccess: action.payload,
      importFileError: null,
    });
  },
  [types.IMPORT_DATA_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      importFileSuccess: null,
      importFileError: action.payload,
    });
  },

  [types.SHOW_HIDE_DEMO_DATA](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      showDemoData: null,
      error: null,
    });
  },
  [types.SHOW_HIDE_DEMO_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      showDemoData: action.payload.show_demo_data,
    });
  },
  [types.SHOW_HIDE_DEMO_DATA_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      error: action.payload,
    });
  },

  [types.ATTACH_FILE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.ATTACH_FILE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      successMessage: action.payload,
      errorMessage: null,
    });
  },
  [types.ATTACH_FILE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      successMessage: null,
      errorMessage: action.payload,
    });
  },

  [types.UPLOAD_ONBOARDING_FILE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.UPLOAD_ONBOARDING_FILE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      successMessage: action.payload,
      errorMessage: null,
    });
  },
  [types.UPLOAD_ONBOARDING_FILE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      successMessage: null,
      errorMessage: action.payload,
    });
  },

  [types.IMPORT_ONBOARDING_DATA](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.IMPORT_ONBOARDING_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      successMessage: action.payload,
      errorMessage: null,
    });
  },
  [types.IMPORT_ONBOARDING_DATA_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      successMessage: null,
      errorMessage: action.payload,
    });
  },
});
