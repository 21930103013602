import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "../../utils/api.config";

const initialState = {
  isLoading: false,
  data: [],
  customerList: {},
  newCustomer: {},
  error: null,
  succMsg: null,
};

export const customer = createReducer(initialState, {
  [types.GET_CUSTOMERS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      error: null,
    });
  },
  [types.GET_CUSTOMERS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      customerList: action.payload,
      newCustomer: null,
      isLoading: false,
    });
  },
  [types.GET_CUSTOMERS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_CUSTOMER](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      error: null,
    });
  },
  [types.ADD_CUSTOMER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      newCustomer: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_CUSTOMER_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_CUSTOMER](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      error: null,
    });
  },
  [types.DELETE_CUSTOMER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      error: null,
    });
  },
  [types.DELETE_CUSTOMER_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_CUSTOMER](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
    });
  },
  [types.PUT_CUSTOMER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      error: null,
    });
  },
  [types.PUT_CUSTOMER_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      error: action.payload,
      isLoading: false,
    });
  },
});
