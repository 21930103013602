import moment from "moment";
import * as types from "../types/index";

const initialState = {
  isLoading: true,
  startDate: moment().clone().startOf("week"),
  endDate: moment().clone().endOf("week"),
};

export const filters = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_FILTER: {
      const { startDate, endDate } = action.payload;
      return {
        ...state,
        isLoading: false,
        startDate,
        endDate,
      };
    }
    default:
      return state;
  }
};
