import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "@utils/api.config";

const initialState = {
  isLoading: false,
  departmentList: {},
  departmentData: {},
  department_Succ: null,
  department_Err: null,
  attach_user_Succ: null,
  attach_user_Err: null,
  attach_user_list: {},
  error: null,
};

export const departmentReducer = createReducer(initialState, {
  [types.GET_DEPARTMENTS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_DEPARTMENTS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      departmentList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_DEPARTMENTS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_DEPARTMENT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      departmentData: null,
      error: null,
    });
  },
  [types.GET_DEPARTMENT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      departmentData: action.payload,
      isLoading: false,
    });
  },
  [types.GET_DEPARTMENT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_DEPARTMENT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.ADD_DEPARTMENT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      department_Succ: action.payload,
      department_Err: null,
      isLoading: false,
    });
  },
  [types.ADD_DEPARTMENT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      department_Succ: null,
      department_Err: action.payload,
      isLoading: false,
    });
  },
  [types.UPDATE_DEPARTMENT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.UPDATE_DEPARTMENT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      department_Succ: action.payload,
      department_Err: null,
    });
  },
  [types.UPDATE_DEPARTMENT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      department_Succ: null,
      department_Err: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_DEPARTMENT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.DELETE_DEPARTMENT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      department_Succ: action.payload,
      department_Err: null,
    });
  },
  [types.DELETE_DEPARTMENT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      department_Succ: null,
      department_Err: action.payload,
      isLoading: false,
    });
  },
  [types.GET_ATTACH_USER](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_ATTACH_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      attach_user_list: action.payload,
      isLoading: false,
    });
  },
  [types.GET_ATTACH_USER_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.ATTACH_USER](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.ATTACH_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      attach_user_Succ: action.payload,
      attach_user_Err: null,
      isLoading: false,
    });
  },
  [types.ATTACH_USER_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      attach_user_Succ: null,
      attach_user_Err: action.payload,
      isLoading: false,
    });
  },
});
