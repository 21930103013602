import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "../../utils/api.config";

const initialState = {
  isLoading: false,
  isItemTypeLoading: false,
  isItemLoading: false,
  data: {},
  itemTypeList: {},
  itemList: {},
  unitList: {},
  categoryList: {},
  newCategory: null,
  error: null,
  succMsg: null,
  errMsg: null,
};

export const itemReducer = createReducer(initialState, {
  [types.GET_ITEM_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isItemTypeLoading: true,
      error: null,
    });
  },
  [types.GET_ITEM_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      itemTypeList: action.payload,
      isItemTypeLoading: false,
    });
  },
  [types.GET_ITEM_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      itemTypeList: {},
      error: action.payload,
      isItemTypeLoading: false,
    });
  },
  [types.DELETE_ITEM_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isItemTypeLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.DELETE_ITEM_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isItemTypeLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.DELETE_ITEM_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isItemTypeLoading: false,
    });
  },
  [types.CREATE_ITEM_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isItemTypeLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.CREATE_ITEM_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isItemTypeLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.CREATE_ITEM_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isItemTypeLoading: false,
    });
  },
  [types.PUT_ITEM_TYPES](state) {
    return Object.assign({}, state, {
      ...state,
      isItemTypeLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.PUT_ITEM_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isItemTypeLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.PUT_ITEM_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isItemTypeLoading: false,
    });
  },
  [types.GET_UNITS](state) {
    return Object.assign({}, state, {
      ...state,
      isItemTypeLoading: true,
      error: null,
    });
  },
  [types.GET_UNITS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      unitList: action.payload,
      isItemTypeLoading: false,
    });
  },
  [types.GET_UNITS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      unitList: {},
      error: action.payload,
      isItemTypeLoading: false,
    });
  },
  [types.GET_ITEMS](state) {
    return Object.assign({}, state, {
      ...state,
      isItemLoading: true,
      error: null,
    });
  },
  [types.GET_ITEMS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      itemList: action.payload,
      isItemLoading: false,
    });
  },
  [types.GET_ITEMS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      itemList: {},
      error: action.payload,
      isItemLoading: false,
    });
  },
  [types.DELETE_ITEM](state) {
    return Object.assign({}, state, {
      ...state,
      isItemLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.DELETE_ITEM_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isItemLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.DELETE_ITEM_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isItemLoading: false,
    });
  },
  [types.CREATE_ITEM](state) {
    return Object.assign({}, state, {
      ...state,
      isItemLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.CREATE_ITEM_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isItemLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.CREATE_ITEM_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isItemLoading: false,
    });
  },
  [types.PUT_ITEM](state) {
    return Object.assign({}, state, {
      ...state,
      isItemLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.PUT_ITEM_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isItemLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.PUT_ITEM_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isItemLoading: false,
    });
  },
  [types.GET_ITEM_TYPE_CATEGORIES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_ITEM_TYPE_CATEGORIES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      categoryList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_ITEM_TYPE_CATEGORIES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      categoryList: {},
      error: action.payload,
      isLoading: false,
    });
  },
  [types.CREATE_ITEM_TYPE_CATEGORY](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      newCategory: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.CREATE_ITEM_TYPE_CATEGORY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      newCategory: action.payload,
    });
  },
  [types.CREATE_ITEM_TYPE_CATEGORY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      errMsg: action.payload,
      isLoading: false,
    });
  },

  // Item summary
  [types.GET_ITEM_SUMMARY](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_ITEM_SUMMARY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      itemCost: action.payload,
      isLoading: false,
    });
  },
  [types.GET_ITEM_SUMMARY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      itemCost: {},
      error: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_ITEMS](state) {
    return Object.assign({}, state, {
      ...state,
      isItemLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.DELETE_ITEMS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isItemLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.DELETE_ITEMS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isItemLoading: false,
    });
  },
});
