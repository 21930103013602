import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "../../utils/api.config";

const initialState = {
  isLoading: false,
  fileSuccess: null,
  fileError: null,
  imageSuccess: null,
  imageError: null,
};

export const fileUploadReducer = createReducer(initialState, {
  [types.UPLOAD_FILE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      fileSuccess: null,
      fileError: null,
    });
  },
  [types.UPLOAD_FILE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      fileSuccess: action.payload,
      fileError: null,
    });
  },
  [types.UPLOAD_FILE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      fileSuccess: null,
      fileError: action.payload,
    });
  },
  [types.DELETE_FILE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      fileSuccess: null,
      fileError: null,
    });
  },
  [types.DELETE_FILE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      fileSuccess: action.payload,
      fileError: null,
    });
  },
  [types.DELETE_FILE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      fileSuccess: null,
      fileError: action.payload,
    });
  },
  [types.UPLOAD_IMAGE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      imageSuccess: null,
      imageError: null,
    });
  },
  [types.UPLOAD_IMAGE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      imageSuccess: action.payload,
      imageError: null,
    });
  },
  [types.UPLOAD_IMAGE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      imageSuccess: null,
      imageError: action.payload,
    });
  },
  [types.DELETE_IMAGE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      imageSuccess: null,
      imageError: null,
    });
  },
  [types.DELETE_IMAGE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      imageSuccess: action.payload,
      imageError: null,
    });
  },
  [types.DELETE_IMAGE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      imageSuccess: null,
      imageError: action.payload,
    });
  },
});
