import * as types from "../types/index";

const intialState = {
  isLoading: false,
  data: {},
  error: null,
  isUserLoggedOut: false,
};

export const logOut = (state = intialState, action) => {
  switch (action.type) {
    case types.LOGOUT:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.LOGOUT_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data,
        isLoading: false,
        isUserLoggedOut: true,
      };
    }
    case types.LOGOUT_FAILURE: {
      const { error } = action.payload;
      return {
        ...intialState,
        error,
      };
    }
    default:
      return state;
  }
};
