import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "../../utils/api.config";

const initialState = {
  isLoading: false,
  data: [],
  taskList: {},
  allTasks: [],
  taskDetail: {},
  totalTasks: 0,
  error: "",
  isTaskExist: false,
  newTask: null,
  taskError: null,
  taskSuccess: null,
};

export const task = createReducer(initialState, {
  [types.GET_TASKS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_TASKS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      taskList: {
        ...state.taskList,
        [action.meta.project_id]: action.payload,
      },
      isLoading: false,
    });
  },
  [types.GET_TASKS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_ALL_TASKS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_ALL_TASKS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      allTasks: action.payload,
      isLoading: false,
    });
  },
  [types.GET_ALL_TASKS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },

  [types.GET_TASK_DETAIL](state) {
    return Object.assign({}, state, {
      ...state,
      taskDetail: {},
      isLoading: true,
      error: null,
    });
  },
  [types.GET_TASK_DETAIL_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      taskDetail: action.payload,
      isLoading: false,
    });
  },
  [types.GET_TASK_DETAIL_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      taskDetail: {},
      error: action.payload,
      isLoading: false,
    });
  },

  [types.ADD_TASK](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      taskError: null,
      taskSuccess: null,
      newTask: null,
      error: null,
    });
  },
  [types.ADD_TASK_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      newTask: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_TASK_FAILURE](state, action) {
    return Object.assign({}, state, {
      ...state,
      taskError: action.payload,
      isTaskExist: true,
      isLoading: false,
    });
  },
  [types.DELETE_TASK](state) {
    return Object.assign({}, state, {
      ...initialState,
      isLoading: true,
      taskError: null,
      newTask: null,
    });
  },
  [types.DELETE_TASK_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      taskSuccess: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_TASK_FAILURE](state, action) {
    return Object.assign({}, state, {
      ...state,
      taskError: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_TASK_DATA](state) {
    return Object.assign({}, state, {
      ...initialState,
      isLoading: true,
      taskError: null,
      newTask: null,
    });
  },
  [types.PUT_TASK_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      taskSuccess: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_TASK_DATA_FAILURE](state, action) {
    return Object.assign({}, state, {
      ...state,
      taskError: action.payload,
      isLoading: false,
    });
  },
});
