import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "@utils/api.config";

const initialState = {
  isLoading: false,
  error: null,
  rulesList: [],
  rulesListLoading: false,
  rulesSuccess: null,
  rulesError: null,
  createRuleResponse: null,

  isLoadingSimplifiedRule: false,
  simplifiedRuleError: null,
  simplifiedRuleSuccess: null,
};

export const rulesReducer = createReducer(initialState, {
  [types.GET_RULES](state) {
    return Object.assign({}, state, {
      ...state,
      rulesList: [],
      rulesListLoading: true,
      error: null,
    });
  },
  [types.GET_RULES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      rulesList: action.payload,
      rulesListLoading: false,
    });
  },
  [types.GET_RULES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      rulesListLoading: false,
    });
  },
  [types.CREATE_RULE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      createRuleResponse: null,
      rulesSuccess: null,
      rulesError: null,
    });
  },
  [types.CREATE_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      createRuleResponse: action.payload,
    });
  },
  [types.CREATE_RULE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      rulesError: action.payload,
      createRuleResponse: null,
      isLoading: false,
    });
  },
  [types.UPDATE_RULE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      rulesSuccess: null,
      rulesError: null,
    });
  },
  [types.UPDATE_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      rulesSuccess: action.payload,
    });
  },
  [types.UPDATE_RULE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      rulesError: action.payload,
      rulesSuccess: null,
      isLoading: false,
    });
  },
  [types.DELETE_RULE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      rulesSuccess: null,
      rulesError: null,
    });
  },
  [types.DELETE_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      rulesSuccess: action.payload,
    });
  },
  [types.DELETE_RULE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      rulesError: action.payload,
      rulesSuccess: null,
      isLoading: false,
    });
  },

  // onboarding: simplified rules
  [types.CREATE_SIMPLIFIED_RULE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoadingSimplifiedRule: true,
      simplifiedRuleError: null,
      simplifiedRuleSuccess: null,
    });
  },
  [types.CREATE_SIMPLIFIED_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoadingSimplifiedRule: false,
      simplifiedRuleError: null,
      simplifiedRuleSuccess: action.payload,
    });
  },
  [types.CREATE_SIMPLIFIED_RULE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      isLoadingSimplifiedRule: false,
      simplifiedRuleError: action.payload,
      simplifiedRuleSuccess: null,
    });
  },
});
