import i18next from "i18next";

export const APP_ROUTES = {
  login: "/login",
  signUp: "/signUp",
  hours: "/hours",
  forgotPassword: "/forgotPassword",
  confirmed: "/confirmed",
  resetPassword: "/reset-password/:reset_password_token",
  postSignup: "/thank-you",
};
export const errorMessages = {
  required: i18next.t("Field is required"),
  passwordMismatch: i18next.t("Password does not match"),
  invalidPassword: i18next.t(
    "Your password must be at least 6 characters long, contain at least one number and have a mixture of uppercase and lowercase letters."
  ),
  invalid: i18next.t("Please enter a valid value"),
  invalidEmail: i18next.t("Please enter a valid email"),
  invalidPhone: i18next.t("Please enter a valid phone number"),
  user_exist: i18next.t("A user with this email already exists!"),
  invalidOrganisationNumber: i18next.t(
    "Organisation number must contain minimum 9 characters"
  ),
  duplicateEmail: i18next.t("Duplicate email"),
  invalidBirthID: i18next.t("Birth ID must contain 11 digits"),
  invalidBankAccountNumber: i18next.t(
    "Bank account number must contain 11 digits"
  ),
  invalidVacationDays: i18next.t("Vacation days must be less than 366 days"),
  invalidPercentage: i18next.t("Please enter value between 1 to 100"),
  invalidSalary: i18next.t("Please enter value between 1 to 999999"),
  invalidEmployees: i18next.t("Number of employees must be positive"),
  invalidLunchTime: i18next.t("Invalid lunch time will be ignored"),
  projectRestriction: i18next.t(
    "Restriction applied in creating, editing and deleting projects."
  ),
  projectEditRestriction: i18next.t(
    "Due to project restrictions, only color and geofence are editable."
  ),
};

export const REQUEST_LOCATION_PERMISSION_MESSAGE = `${i18next.t(
  "Your company has enabled geofence restrictions. Please grant the app access to your location to register hours."
)}`;

export const DENIED_LOCATION_PERMISSION_MESSAGE = `${i18next.t(
  "The app was unable to access your location. Please grant location access in your browser settings."
)}`;

export const ANDROID_APP_LINK =
  "https://play.google.com/store/apps/details?id=com.visualregistration.v2";
export const IPHONE_APP_LINK =
  "https://apps.apple.com/us/app/visual-registration-system/id1448200919";
export const APP_DEEP_LINK = "https://vrsv2.page.link/NLtk";

export const TABLET_STAGING_LINK = "https://stg-tablet.visualregistration.no";
export const TABLET_PRODUCTION_LINK = "https://tablet.visualregistration.no";

export const PRIVACY_POLICY_LINK =
  "https://en.visualregistration.no/personvernerklaering";
export const PRIVACY_POLICY_LINK_NORWEGIAN =
  "https://visualregistration.no/personvernerklaering";

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_SUB_ITEM: 20,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const SYNC_STATUS = {
  not_sync: { title: i18next.t("Failed"), color: "error.main" },
  sent: { title: i18next.t("Syncing"), color: "info.main" },
  sync: { title: i18next.t("Synced"), color: "success.dark" },
  not_valid: { title: i18next.t("Invalid"), color: "error.main" },
};
