import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "../../utils/api.config";

const initialState = {
  isLoading: false,
  isUsersLoading: false,
  sickLeaveList: {},
  sickLeaveUsers: {},
  error: null,
  succMsg: null,
  errMsg: null,
};

export const sickLeaveReducer = createReducer(initialState, {
  [types.GET_SICK_LEAVES](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      sickLeaveList: {},
      error: null,
    });
  },
  [types.GET_SICK_LEAVES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      sickLeaveList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_SICK_LEAVES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_SICK_LEAVE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.DELETE_SICK_LEAVE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.DELETE_SICK_LEAVE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.CREATE_SICK_LEAVE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.CREATE_SICK_LEAVE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.CREATE_SICK_LEAVE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_SICK_LEAVE](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.PUT_SICK_LEAVE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.PUT_SICK_LEAVE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },

  [types.GET_SICK_LEAVE_USERS](state) {
    return Object.assign({}, state, {
      ...state,
      isUsersLoading: true,
      sickLeaveUsers: {},
      error: null,
    });
  },
  [types.GET_SICK_LEAVE_USERS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      sickLeaveUsers: action.payload,
      isUsersLoading: false,
    });
  },
  [types.GET_SICK_LEAVE_USERS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isUsersLoading: false,
    });
  },
  [types.DELETE_SICK_LEAVE_USER](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.DELETE_SICK_LEAVE_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.DELETE_SICK_LEAVE_USER_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_SICK_LEAVE_USER](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.ADD_SICK_LEAVE_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.ADD_SICK_LEAVE_USER_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_SICK_LEAVE_USER](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.PUT_SICK_LEAVE_USER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.PUT_SICK_LEAVE_USER_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
});
