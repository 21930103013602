import {
  Button,
  Typography,
  Container,
  Box,
  Fade,
  Grow,
  Link,
} from "@mui/material";
import VrsLogo from "@/assets/images/vrsLogo.svg?react";
import * as Sentry from "@sentry/browser";

const SUPPORT_EMAIL = "support@vrs.no";
const SUBJECT = "Bug in VRS Frontend";
const EMAIL_BODY = `
  Hello Support Team,

  I encountered a bug and wanted to report it.

  - Description:

  - Steps to Reproduce: 

  - Expected Result: 

  - Actual Result: 

  Thank you,
  [Your Name]
`;

export default function ErrorPage() {
  const encodedSubject = encodeURIComponent(SUBJECT);
  const encodedBody = encodeURIComponent(EMAIL_BODY);
  const mailtoLink = `mailto:${SUPPORT_EMAIL}?subject=${encodedSubject}&body=${encodedBody}`;

  const handleReportIssue = () => {
    const eventId = Sentry.lastEventId();
    Sentry.showReportDialog({
      eventId: eventId,
    });
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          textAlign: "center",
          alignItems: "center",
          margin: "auto",
          minHeight: "80vh",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grow in timeout={800}>
          <div>
            <VrsLogo fill={"black"} height={38} />
          </div>
        </Grow>
        <Fade in timeout={1300}>
          <div>
            <Typography variant="h3" paragraph>
              Oops! Something went wrong.
            </Typography>

            <Typography sx={{ color: "text.secondary" }}>
              Our team has been notified. Click the button below to reload the
              application or <Link href={mailtoLink}>contact support </Link> if
              the issue persists.
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="row"
              gap={2}
              my={3}
            >
              <Button
                onClick={() => {
                  window.location.reload();
                }}
                size="large"
                variant="contained"
              >
                Reload application
              </Button>
              <Button onClick={() => handleReportIssue()}>
                Report Problem
              </Button>
            </Box>
          </div>
        </Fade>
      </Box>
    </Container>
  );
}
