import PropTypes from "prop-types";
import { createContext, useState } from "react";
import getLanguage from "@utils/getLanguage";
import { getLocale } from "@utils/dateTimeFunctions";
import i18next from "i18next";

import moment from "moment";

// ----------------------------------------------------------------------

const initialState = {
  language: getLanguage(),
  locale: getLocale(getLanguage()),
  handleLanguageChange: () => {},
};

const LanguageContext = createContext(initialState);

// ----------------------------------------------------------------------

LanguageProvider.propTypes = {
  children: PropTypes.node,
};

function LanguageProvider({ children }) {
  const [language, setLanguage] = useState(initialState.language);
  const [locale, setLocale] = useState(initialState.locale);

  const handleLanguageChange = (newLanguage) => {
    const newLocale = getLocale(newLanguage);

    setLanguage(newLanguage);
    setLocale(newLocale);

    moment.locale(newLocale);

    i18next.changeLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider
      value={{
        language: language,
        locale: locale,
        handleLanguageChange,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export { LanguageProvider, LanguageContext };
