import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "./app/i18n";
import packageInfo from "../package.json";
import "moment/dist/locale/nb";
import "moment/dist/locale/en-ca";
import "moment/dist/locale/de";
import "moment/dist/locale/ro";
import "moment/dist/locale/sv";
import "moment/dist/locale/da";
import "moment/dist/locale/fr";
import "moment/dist/locale/th";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import App from "./app/App";

import getLanguage from "@utils/getLanguage";
import { getLocale } from "@utils/dateTimeFunctions";

momentDurationFormatSetup(moment);

// Set moment locale based on the current language. This is required on event of page reload
moment.locale(getLocale(getLanguage()));

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({ maskAllText: true, blockAllMedia: true }),
    ],
    tracesSampleRate: 0.25,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_RELEASE_ENV,
    release: packageInfo.version,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
