import createReducer from "@/utils/createReducer";
import * as types from "../types/index";
import { logout } from "@utils/api.config";

const initialState = {
  isLoading: false,
  data: [],
  projectList: {},
  projectData: {},
  addProjectSuccess: null,
  addProjectError: null,
  updateProjectSuccess: null,
  updateProjectError: null,
  deleteProjectSuccess: null,
  deleteProjectError: null,
  totalProjects: 0,
  error: "",
  userList: {},
  projectOverview: {},
  topProjectOverview: {},
  projectCostData: {},
  projectResponsibles: [],
};

export const projectReducer = createReducer(initialState, {
  [types.GET_PROJECTS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_PROJECTS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      projectList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PROJECTS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PROJECT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      projectData: null,
      error: null,
    });
  },
  [types.GET_PROJECT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      projectData: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PROJECT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_PROJECTS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      addProjectSuccess: null,
      addProjectError: null,
      updateProjectSuccess: null,
      updateProjectError: null,
      deleteProjectSuccess: null,
      deleteProjectError: null,
      error: null,
    });
  },
  [types.DELETE_PROJECTS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      deleteProjectSuccess: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_PROJECTS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      deleteProjectError: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_PROJECTS_DATA](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      addProjectSuccess: null,
      addProjectError: null,
      updateProjectSuccess: null,
      updateProjectError: null,
      deleteProjectSuccess: null,
      deleteProjectError: null,
      error: null,
    });
  },
  [types.PUT_PROJECTS_DATA_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      addProjectSuccess: null,
      addProjectError: null,
      updateProjectSuccess: action.payload,
      updateProjectError: null,
      isLoading: false,
    });
  },
  [types.PUT_PROJECTS_DATA_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      addProjectSuccess: null,
      addProjectError: null,
      updateProjectError: action.payload,
      updateProjectSuccess: null,
      isLoading: false,
    });
  },
  [types.ADD_PROJECT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      addProjectSuccess: null,
      addProjectError: null,
      updateProjectSuccess: null,
      updateProjectError: null,
      deleteProjectSuccess: null,
      deleteProjectError: null,
      error: null,
    });
  },
  [types.ADD_PROJECT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      addProjectSuccess: action.payload,
      addProjectError: null,
      updateProjectSuccess: null,
      updateProjectError: null,
      isLoading: false,
    });
  },
  [types.ADD_PROJECT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      addProjectError: action.payload,
      addProjectSuccess: null,
      updateProjectSuccess: null,
      updateProjectError: null,
      isLoading: false,
    });
  },
  [types.GET_USES_BY_PROJECT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_USES_BY_PROJECT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_USES_BY_PROJECT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },

  [types.GET_PROJECT_OVERVIEW](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_PROJECT_OVERVIEW_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      projectOverview: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PROJECT_OVERVIEW_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      projectOverview: {},
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_TOP_PROJECT_OVERVIEW](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_TOP_PROJECT_OVERVIEW_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      topProjectOverview: action.payload,
      isLoading: false,
    });
  },
  [types.GET_TOP_PROJECT_OVERVIEW_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      topProjectOverview: {},
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PROJECT_COST](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      projectCostData: null,
      error: null,
    });
  },
  [types.GET_PROJECT_COST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      projectCostData: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PROJECT_COST_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PROJECT_RESPONSIBLES](state) {
    return Object.assign({}, state, {
      ...state,
      projectResponsibles: [],
      isLoading: true,
      error: null,
    });
  },
  [types.GET_PROJECT_RESPONSIBLES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      projectResponsibles: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PROJECT_RESPONSIBLES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
});
