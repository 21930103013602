import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "../../utils/api.config";

const initialState = {
  isLoading: false,
  planLoading: false,
  userLoading: false,
  userList: {},
  planList: {},
  userPlans: {},
  planData: null,
  availability: null,
  error: null,
  succMsg: null,
  errMsg: null,
  specificPlanUsersList: {},
  specificPlanUsersLoading: false,
  approvalSuccess: null,
  approvalError: null,
  approvalUserList: {},
  approvalDetails: {},
  approvalDetailsLoading: false,
  approvalPlanList: {},
  approvalUserDetails: {},
  planSchedule: null,
  planScheduleList: {},
  planScheduleLoading: false,

  // staffing
  isLoadingStaffing: false,
  staffing: {},
  isLoadingStaffingDetails: false,
  staffingDetails: [],
};

export const planningReducer = createReducer(initialState, {
  [types.GET_COMPANY_USERS](state) {
    return Object.assign({}, state, {
      ...state,
      userLoading: true,
      error: null,
    });
  },
  [types.GET_COMPANY_USERS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userList: action.payload,
      userLoading: false,
    });
  },
  [types.GET_COMPANY_USERS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      userLoading: false,
    });
  },
  [types.GET_PLANS](state) {
    return Object.assign({}, state, {
      ...state,
      planLoading: true,
      error: null,
    });
  },
  [types.GET_PLANS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      planList: action.payload,
      planLoading: false,
    });
  },
  [types.GET_PLANS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      planLoading: false,
    });
  },
  [types.CREATE_PLAN](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.CREATE_PLAN_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.CREATE_PLAN_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PLAN](state) {
    return Object.assign({}, state, {
      ...state,
      planData: null,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_PLAN_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      planData: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PLAN_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.UPDATE_PLAN](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.UPDATE_PLAN_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.UPDATE_PLAN_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_PLAN](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.DELETE_PLAN_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.DELETE_PLAN_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.GET_AVAILABILITY](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      availability: null,
      error: null,
    });
  },
  [types.GET_AVAILABILITY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      availability: action.payload.availability
        ? action.payload.availability
        : action.payload,
      isLoading: false,
    });
  },
  [types.GET_AVAILABILITY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_SPECIFIC_PLAN_USERS](state) {
    return Object.assign({}, state, {
      ...state,
      specificPlanUsersList: {},
      specificPlanUsersLoading: true,
      error: null,
    });
  },
  [types.GET_SPECIFIC_PLAN_USERS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      specificPlanUsersList: action.payload,
      specificPlanUsersLoading: false,
    });
  },
  [types.GET_SPECIFIC_PLAN_USERS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      specificPlanUsersLoading: false,
    });
  },
  [types.CREATE_PLAN_MULTI_PROJECT](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.CREATE_PLAN_MULTI_PROJECT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.CREATE_PLAN_MULTI_PROJECT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.ASSIGN_PLAN](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.ASSIGN_PLAN_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.ASSIGN_PLAN_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.APPROVE_PLAN](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.APPROVE_PLAN_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.APPROVE_PLAN_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.REJECT_PLAN](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.REJECT_PLAN_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
      approvalError: null,
    });
  },
  [types.REJECT_PLAN_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      approvalSuccess: null,
      isLoading: false,
    });
  },
  [types.APPROVE_USER_PLAN](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.APPROVE_USER_PLAN_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
    });
  },
  [types.APPROVE_USER_PLAN_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      isLoading: false,
    });
  },
  [types.REJECT_USER_PLAN](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      approvalSuccess: null,
      approvalError: null,
    });
  },
  [types.REJECT_USER_PLAN_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      approvalSuccess: action.payload,
    });
  },
  [types.REJECT_USER_PLAN_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      approvalError: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PLAN_APPROVAL_DETAILS](state) {
    return Object.assign({}, state, {
      approvalDetailsLoading: true,
      approvalDetails: {},
      error: null,
    });
  },
  [types.GET_PLAN_APPROVAL_DETAILS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      approvalDetails: action.payload,
      approvalDetailsLoading: false,
    });
  },
  [types.GET_PLAN_APPROVAL_DETAILS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      approvalDetailsLoading: false,
    });
  },
  [types.GET_PLAN_APPROVAL_USERS](state) {
    return Object.assign({}, state, {
      isLoading: true,
      approvalUserList: {},
      error: null,
    });
  },
  [types.GET_PLAN_APPROVAL_USERS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      approvalUserList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PLAN_APPROVAL_USERS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },

  [types.GET_PLAN_APPROVAL_PLANS](state) {
    return Object.assign({}, state, {
      isLoading: true,
      approvalPlanList: {},
      error: null,
    });
  },
  [types.GET_PLAN_APPROVAL_PLANS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      approvalPlanList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PLAN_APPROVAL_PLANS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PLAN_APPROVAL_USER_DETAILS](state) {
    return Object.assign({}, state, {
      approvalDetailsLoading: true,
      approvalUserDetails: {},
      error: null,
    });
  },
  [types.GET_PLAN_APPROVAL_USER_DETAILS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      approvalUserDetails: action.payload,
      approvalDetailsLoading: false,
    });
  },
  [types.GET_PLAN_APPROVAL_USER_DETAILS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      approvalDetailsLoading: false,
    });
  },
  [types.GET_PLAN_SCHEDULES](state) {
    return Object.assign({}, state, {
      ...state,
      planScheduleList: {},
      planScheduleLoading: true,
      error: null,
    });
  },
  [types.GET_PLAN_SCHEDULES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      planScheduleList: action.payload,
      planScheduleLoading: false,
    });
  },
  [types.GET_PLAN_SCHEDULES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      planScheduleLoading: false,
    });
  },
  [types.DELETE_PLAN_SCHEDULE](state) {
    return Object.assign({}, state, {
      ...state,
      planScheduleLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.DELETE_PLAN_SCHEDULE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      planScheduleLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.DELETE_PLAN_SCHEDULE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      planScheduleLoading: false,
    });
  },
  [types.CREATE_PLAN_SCHEDULE](state) {
    return Object.assign({}, state, {
      ...state,
      planScheduleLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.CREATE_PLAN_SCHEDULE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      planScheduleLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.CREATE_PLAN_SCHEDULE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      planScheduleLoading: false,
    });
  },
  [types.GET_PLAN_SCHEDULE](state) {
    return Object.assign({}, state, {
      ...state,
      planSchedule: null,
      planScheduleLoading: true,
      error: null,
    });
  },
  [types.GET_PLAN_SCHEDULE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      planSchedule: action.payload,
      planScheduleLoading: false,
    });
  },
  [types.GET_PLAN_SCHEDULE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      planScheduleLoading: false,
    });
  },
  [types.PUT_PLAN_SCHEDULE](state) {
    return Object.assign({}, state, {
      ...state,
      planScheduleLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.PUT_PLAN_SCHEDULE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      planScheduleLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.PUT_PLAN_SCHEDULE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      planScheduleLoading: false,
    });
  },
  [types.GET_USER_PLANS](state) {
    return Object.assign({}, state, {
      ...state,
      userPlans: {},
      planLoading: true,
      error: null,
    });
  },
  [types.GET_USER_PLANS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userPlans: action.payload,
      planLoading: false,
    });
  },
  [types.GET_USER_PLANS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      planLoading: false,
    });
  },
  [types.DELETE_PLANS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.DELETE_PLANS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.DELETE_PLANS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },

  // staffing
  [types.GET_PLAN_STAFFING](state) {
    return Object.assign({}, state, {
      ...state,
      isLoadingStaffing: true,
      staffing: {},
      error: null,
    });
  },
  [types.GET_PLAN_STAFFING_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoadingStaffing: false,
      staffing: action.payload,
    });
  },
  [types.GET_PLAN_STAFFING_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      isLoadingStaffing: false,
      error: action.payload,
    });
  },

  [types.GET_PLAN_STAFFING_DETAIL](state) {
    return Object.assign({}, state, {
      ...state,
      isLoadingStaffingDetails: true,
      staffingDetails: [],
      error: null,
    });
  },
  [types.GET_PLAN_STAFFING_DETAIL_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoadingStaffingDetails: false,
      staffingDetails: action.payload,
    });
  },
  [types.GET_PLAN_STAFFING_DETAIL_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      isLoadingStaffingDetails: false,
      error: action.payload,
    });
  },
});
