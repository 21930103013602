import moment from "moment";
import getLanguage from "@utils/getLanguage";

export const getStartAndEndOfWeek = () => {
  const momentObj = moment();
  return {
    start_time: momentObj.startOf("week"),
    end_time: momentObj.endOf("week"),
  };
};

export const getStartAndEndTimeFromAmount = (
  selectedDate,
  hours = 0,
  minutes = 0,
  start_Time
) => {
  // const momentObj = moment(selectedDate);
  let startTime, endTime;
  if (start_Time && parseFloat(start_Time) > 0) {
    startTime = moment(selectedDate)
      .startOf("day")
      .add(start_Time.split(`:`)[0], "h")
      .add(start_Time.split(`:`)[1], "m");
    endTime = moment(startTime).add(hours, "h").add(minutes, "m");
  } else {
    startTime = moment(selectedDate).startOf("day");
    endTime = moment(selectedDate).add(hours, "h").add(minutes, "m");
  }
  return {
    startTime,
    endTime,
  };
};

export const getAmountFromStartAndEndTime = (startTime, endTime) => {
  const diffTime = moment(endTime).diff(moment(startTime));
  return moment.duration(diffTime).asHours();
};

export const getDurationFromStartAndEndTime = (startTime, endTime) => {
  const diffTime = moment(endTime).diff(moment(startTime));
  const duration = moment.duration(diffTime);

  return duration.hours() + "." + duration.minutes();
};

export const getWeekday = (startDate) => {
  const dayNumber = moment(startDate).isoWeekday();
  switch (dayNumber) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 7:
      return "Sunday";
    default:
      break;
  }
};

export const getDateOfISOWeek = (newWeekday) => {
  return moment().day(newWeekday);
};

export const getLocale = (language) => {
  // In en-ca locale, Sunday is first day of week so en-ca is used for en (English)
  if (language === "nb") {
    return "nb";
  } else if (language === "de") {
    return "de";
  } else if (language === "ro") {
    return "ro";
  } else if (language === "sv") {
    return "sv";
  } else if (language === "da") {
    return "da";
  } else if (language === "fr") {
    return "fr";
  } else if (language === "th") {
    return "th";
  } else {
    return "en-ca";
  }
};

export const isWeekStartWithMonday = () => {
  const language = getLanguage();

  return language === "en" ? false : true;
};

export const getTimeFormat = () => {
  const language = getLanguage();

  if (language === "en") {
    return "h:mm a";
  } else {
    return "H:mm"; // HH:mm
  }
};

export const getDateAndDayFormat = () => {
  const language = getLanguage();

  if (language === "en") {
    return "MM.DD.YY, ddd";
  } else {
    return "DD.MM.YY, ddd";
  }
};

export const getDateAndTimeFormat = () => {
  const language = getLanguage();

  if (language === "en") {
    return "MM.DD.YY, h:mm a";
  } else {
    return "DD.MM.YY, H:mm";
  }
};

export const getDateFormat = () => {
  const language = getLanguage();

  if (language === "en") {
    return "MM.DD.YY";
  } else {
    return "DD.MM.YY";
  }
};

export const getFullYearDateFormat = () => {
  const language = getLanguage();

  if (language === "en") {
    return "MM.DD.YYYY";
  } else {
    return "DD.MM.YYYY";
  }
};

export const enumerateDaysBetweenDates = (
  startDate,
  endDate,
  ignoreWeekEnds = false
) => {
  let now = moment(startDate).clone();
  let dates = [];

  while (now.isSameOrBefore(endDate)) {
    if (ignoreWeekEnds) {
      const dayNumber = now.isoWeekday();
      if (dayNumber !== 6 && dayNumber !== 7) {
        dates.push(now.format("YYYY-MM-DD"));
      }
    } else {
      dates.push(now.format("YYYY-MM-DD"));
    }

    now.add(1, "days");
  }

  return dates;
};

export const isValidMoment = (date) => {
  return moment.isMoment(date) && date.isValid();
};
