import createReducer from "@utils/createReducer";
import * as types from "../types/index";
import { logout } from "../../utils/api.config";

const initialState = {
  isLoading: false,
  data: {},
  tagList: {},
  matchList: [],
  matchLoading: false,
  error: null,
  succMsg: null,
  errMsg: null,
};

export const tagReducer = createReducer(initialState, {
  [types.GET_PROJECT_TAGS](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_PROJECT_TAGS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      tagList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PROJECT_TAGS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      tagList: {},
      error: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_PROJECT_TAG](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.DELETE_PROJECT_TAG_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.DELETE_PROJECT_TAG_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.CREATE_PROJECT_TAG](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.CREATE_PROJECT_TAG_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.CREATE_PROJECT_TAG_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_PROJECT_TAG](state) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.PUT_PROJECT_TAG_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.PUT_PROJECT_TAG_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.GET_PROJECT_TAG_MATCHES](state) {
    return Object.assign({}, state, {
      ...state,
      matchList: [],
      matchLoading: true,
      error: null,
    });
  },
  [types.GET_PROJECT_TAG_MATCHES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      matchList: action.payload,
      matchLoading: false,
    });
  },
  [types.GET_PROJECT_TAG_MATCHES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...state,
      error: action.payload,
      matchLoading: false,
    });
  },
});
